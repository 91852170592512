<template>
  <div class="UserDomainsReserveView">
    <UserDomainsReserve/>
  </div>
</template>

<script>
import UserDomainsReserve from '@/components/UserDomainsReserve';

export default {
  name: 'UserDomainsReserveView',
  components: {
    UserDomainsReserve,
  },
};
</script>
