<template>
  <div class="UserDomainView">
    <md-progress-bar md-mode="indeterminate" v-if="loaders.fetchUserDomain" />
    <div v-else>
      <div v-if="userDomainError">
        <ErrorCard :mainMessage="messages.error.main" />
      </div>
      <div v-else class="o-container-gray">
        <section class="container-full-white">
          <h2 class="o-container o-default-h2">
            {{ $t('userDomainView.manageYourDomain') }} <span class="highlighted">{{ $route.params.domain }}.{{
              $route.params.tld }}</span>
          </h2>
          <div class="o-container container-item">
            <h3 class="o-default-h3">{{ $t('userDomainView.summaryTitle') }}</h3>
            <md-table md-card v-model="userDomain">
              <md-table-row>
                <md-table-head>{{ $t('userDomainView.registerDate') }}</md-table-head>
                <md-table-head>{{ $t('userDomainView.expireDate') }}</md-table-head>
                <md-table-head>{{ $t('userDomainView.domainStatus') }}</md-table-head>
              </md-table-row>
              <md-table-row class="u-text-center">
                <md-table-cell>
                  {{ userDomain.registerDate }}
                </md-table-cell>
                <md-table-cell>
                  {{ userDomain.expireDate }}
                </md-table-cell>
                <md-table-cell class="u-text-capitialize"
                  v-bind:class="getDomainStatusLabelClass(this.userDomain.domainStatus)">
                  {{ userDomain.domainStatus }}
                </md-table-cell>
              </md-table-row>
            </md-table>
          </div>
          <md-divider class="container-divider"></md-divider>
          <div class="o-container container-item">
            <h3 class="o-default-h3">{{ $t('userDomainView.transferDomain') }}</h3>
            <md-field>
              <label class="u-text-left">{{ $t('userDomainView.inputLabel') }}</label>
              <md-input name="transfer-domain-key" v-model.trim="transferDomainKey" />
              <md-button type="submit" class="md-dense md-raised md-primary phone-input-btn green-bg"
                @click="transferDomain($route.params.domain, $route.params.tld, transferDomainKey);">
                {{ $t('userDomainView.transferBtn') }}
              </md-button>
            </md-field>
            <span class="md-error" v-if="transferDomainError !== null && transferDomainError !== 'success'">{{
              $t('userDomainView.error.transferDomain') }}</span>
            <span class="u-green" v-if="transferDomainError === 'success'">{{
              $t('userDomainView.successfulTransferDomain') }}</span>
          </div> <!-- /.o-container -->
          <md-divider class="container-divider"></md-divider>
          <div class="o-container add-to-cart-container container-item">
            <section class="add-to-cart-item">
              <h3 class="o-default-h3 add-to-cart-header">{{ $t('searchedDomains.addBtn') }}</h3>
              <div class="o-default-body-xs-lighty u-strong reservation-expire-date">
                <span>{{ $t('userDomainView.registrationAvailability') }}</span>
                <span class="u-text-capitialize">{{ this.userDomain.expireDate }} </span>
              </div>
            </section>
            <section class="add-to-cart-item">
              <div class="o-default-body u-green">Renew domain for €7.00/year</div>
              <md-button class="md-dense md-raised md-primary btn-add-to-cart"
                @click="addToCartDomain(userDomain.id, userDomain.domainStatus);">
                {{ $t('common.actions.addToCart') }}
              </md-button>
            </section>
          </div>
        </section>
        <section class="container-full-white">
          <div class="o-container container-nameservers">
            <h3 class="o-default-h3">{{ $t('userDomainView.nameserversTitle') }}</h3>
            <md-table md-card v-model="userDomain">
              <md-table-row slot="md-table-row" v-for="(nameserver, key, index) of userDomain.nameservers" :key="key">
                <md-table-cell md-sort-by="key" :class="getNameserversValidationClass(key)">
                  ns{{ index + 1 }}:
                </md-table-cell>
                <md-table-cell md-sort-by="nameserver" class="u-text-left">
                  <md-field :class="getNameserversValidationClass(key)">
                    <md-input v-model="userDomain.nameservers[key]" @blur="validateInput(key)" @keyup="hideAllErrors()"
                      @change="setNameserversChanged(true)" id="nameserverInput" class="u-strong"
                      name="nameserver-input">
                    </md-input>
                    <span class="md-error" v-if="$v.userDomain.nameservers.$each[key].$error">{{
                      $t('userDomainView.error.invalidNameserver') }}</span>
                  </md-field>
                </md-table-cell>
              </md-table-row>
            </md-table>
            <md-progress-bar md-mode="indeterminate" v-if="loaders.sendingFormNameservers" />
            <md-button @click="validateNameservers(userDomain.nameservers)"
              class="md-dense md-raised md-primary btn-change-nameservers">
              {{ $t('userDomainView.updateNameserver') }}
            </md-button>
            <div v-if="this.errors.showNameserversUnchanged" class="o-default-body u-red nameserver-error-all">{{
              $t('userDomainView.error.showNameserversUnchanged') }}</div>
            <div v-if="$v.userDomain.nameservers.$each.$error" class="o-default-body u-red nameserver-error-all">{{
              $t('userDomainView.error.invalidNameserverAll') }}<br>{{
                $t('userDomainView.error.nameserverFurtherIssues') }} {{ $t('contactEmailInreg') }}</div>
            <div v-if="this.errors.showUserDomainNameserverError && userDomainNameserverError"
              class="o-default-body u-red nameserver-error-all">
              <span class="u-strong">Server response:</span>
              <br>
              Status: {{ userDomainNameserverError.status }}
              <br>
              Error: {{ userDomainNameserverError.error }}
              <br>
              Message: {{ userDomainNameserverError.message }}
            </div>
            <div v-if="this.showNameserverUpdateConfirmation" class="o-default-body u-green nameserver-error-all">
              Nameservers successfully updated!</div>
          </div> <!-- /.o-container -->
        </section>
        <md-dialog :md-active.sync="showAddToCartErrorDialog">
          <md-dialog-title>{{ $t('common.userDomains.title') }}</md-dialog-title>
          <md-dialog-content>
            <p class="u-font-body-regular o-dialog-content">{{ $t('userDomainsStatus.alreadyAdded') }}</p>
          </md-dialog-content>
          <md-dialog-actions>
            <md-button class="md-primary" @click="this.hideDomainAlreadyAddedDialog">{{ $t('common.actions.close')
              }}</md-button>
          </md-dialog-actions>
        </md-dialog>
        <md-snackbar :md-position="snackbar.position" :md-duration="snackbar.duration"
          :md-active.sync="snackbar.showSnackbar" md-persistent>
          <span>{{ this.messages.addedToCart }}</span>
          <md-button class="md-primary" @click="snackbar.showSnackbar = false">{{ $t('common.actions.close')
            }}</md-button>
        </md-snackbar>
      </div> <!-- /.o-container-gray -->
    </div>
  </div> <!-- /.UserDomainView -->
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import ErrorCard from '@/components/util/ErrorCard';
import {
  ADD_TO_CART,
  FETCH_USER_DOMAIN,
  POST_USER_DOMAIN_NAMESERVERS,
  USER_DOMAIN_RESET_STATE,
  TRANSFER_DOMAIN,
} from '@/store/actions.type';
import {
  SET_USER_DOMAIN_ERROR,
  SET_ADD_ERROR
} from '@/store/mutations.type';
export default {
  name: 'UserDomainView',
  components: {
    ErrorCard,
  },
  mixins: [validationMixin],
  created() {
    this.$store.dispatch(USER_DOMAIN_RESET_STATE);
  },
  mounted() {
    try {
      if (this.$router.currentRoute.params !== undefined) {
        this.loaders.fetchUserDomain = true;
        this.$store.dispatch(FETCH_USER_DOMAIN, this.$router.currentRoute.params)
          .catch((error) => {
            this.$store.commit(SET_USER_DOMAIN_ERROR, error);
          })
          .finally(() => {
            this.loaders.fetchUserDomain = false;
          });
      }
    } catch (error) {
      this.$store.commit(SET_USER_DOMAIN_ERROR, error);
    }
  },
  data() {
    return {
      transferDomainKey: '',
      errors: {
        validUserDomainNameserver: false,
        validUserDomainNameservers: false,
        showInvalidUserDomainNameserver: false,
        showInvalidUserDomainNameservers: false,
        showNameserversUnchanged: false,
        showUserDomainNameserverError: false
      },
      domainStatusMap: {
        reserved: 'reserved',
        registered: 'registered',
        pendingDelete: 'pendingDelete'
      },
      labels: {
        id: 'iD' // @todo
      },
      loaders: {
        fetchUserDomain: false,
        sendingFormNameservers: false
      },
      messages: {
        addedToCart: this.$t('userDomainsStatus.addedToCart'),
        error: {
          main: this.$t('userDomains.error.domainNotFound1') + this.$t('myAccountView.myDomains') + this.$t('userDomains.error.domainNotFound2'),
        }
      },
      snackbar: {
        showSnackbar: false,
        duration: 2000,
        position: 'center',
      },
      snackbarDeletedFromCart: {
        showSnackbarDeletedFromCart: false,
        duration: 2000,
        position: 'center',
      },
      nameserversChanged: false,
      showNameserverUpdateConfirmation: false,
      showAddToCartErrorDialog: false
    };
  },
  computed: {
    ...mapGetters([
      'addToCartError',
      'entries',
      'transferDomainError',
      'userDomain',
      'userDomainError',
      'userDomainNameserverError',
    ])
  },
  methods: {
    addToCartDomain(id, domainStatus) {
      domainStatus = 'RENEW';
      const params = [{
        id: id,
        period: 1,
        domainType: domainStatus
      }];
      this.$store.dispatch(ADD_TO_CART, params).then(() => {
        if (this.addToCartError === false) {
          this.showCopyConfirmation();
        } else {
          this.messages.error.addCart = this.messages.error.alreadyAdded;
          this.showAddToCartErrorDialog = true;
          this.$store.commit(SET_ADD_ERROR, false);
        }
      });
    },
    transferDomain(domain, tld, key) {
      domain = domain + '.' + tld;
      let payload = {
        domain: domain,
        key: key
      }
      this.$store.dispatch(TRANSFER_DOMAIN, payload);
    },
    getNameserversValidationClass(key) {
      const field = this.$v.userDomain.nameservers.$each[key]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    getDomainStatusLabelClass(domainStatus) {
      const warningClass = 'u-yellow',
        successClass = 'u-green',
        errorClass = 'u-red',
        orangeClass = 'u-orange';

      switch (domainStatus) {
        case this.domainStatusMap.reserved:
          return warningClass;
        case this.domainStatusMap.registered:
          return successClass;
        case this.domainStatusMap.pendingDelete:
          return errorClass;
        default:
          // eslint-disable-next-line
          // console.log('Applying fallback domain status.');
          return orangeClass;
      }
    },
    changeNameservers(userDomainNameservers) {
      let postUserDomainNameserversPayload = {
        domainId: this.userDomain.id,
        nameservers: {
          ns1: userDomainNameservers.ns1,
          ns2: userDomainNameservers.ns2,
          ns3: userDomainNameservers.ns3,
          ns4: userDomainNameservers.ns4,
          ns5: userDomainNameservers.ns5,
          ns6: userDomainNameservers.ns6
        }
      }
      this.loaders.sendingFormNameservers = true;
      this.$store
        .dispatch(POST_USER_DOMAIN_NAMESERVERS, postUserDomainNameserversPayload)
        .then(() => {
          this.loaders.sendingFormNameservers = false;
          if (!this.userDomainNameserverError) {
            this.showNameserverUpdateConfirmation = true;
          }
        })
        .catch(() => {
          this.loaders.sendingFormNameservers = false;
          this.errors.showUserDomainNameserverError = true;
        });
    },
    showCopyConfirmation() {
      this.snackbar.showSnackbar = true;
    },
    validateInput(key) {
      this.errors.showNameserversUnchanged = false;
      this.showNameserverUpdateConfirmation = false;
      this.errors.showUserDomainNameserverError = false;
      this.$v.userDomain.nameservers.$each[key].$touch()
    },
    validateNameservers(userDomainNameservers) {
      if (this.nameserversChanged) {
        this.errors.showNameserversUnchanged = false;
        this.$v.userDomain.nameservers.$each.$touch()
        if (!this.$v.userDomain.nameservers.$each.$error) {
          this.changeNameservers(userDomainNameservers)
        }
      } else {
        this.errors.showNameserversUnchanged = true;
      }
    },
    setNameserversChanged(flag) {
      this.nameserversChanged = flag;
      this.showNameserversUnchanged = !flag
    },
    hideAllErrors() {
      this.errors.showInvalidUserDomainNameservers = false;
      this.errors.showNameserversUnchanged = false;
      this.errors.showUserDomainNameserverError = false;
      this.showNameserverUpdateConfirmation = false;
    },
    hideDomainAlreadyAddedDialog() {
      this.showAddToCartErrorDialog = false;
    },
  },
  validations() {
    const isNameserver = function (value) {
      // Regex to check valid nameserver
      const isNameserverRegex = /^(([a-zA-Z]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/;
      if (value) {
        if (isNameserverRegex.test(value)) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }

    return {
      userDomain: {
        nameservers: {
          $each: {
            isNameserver: isNameserver
          },
        }
      },
    }
  }
}
</script>

<style lang="scss" scoped>
.UserDomainView {

  .md-invalid .md-table-cell-container {
    color: $palette-error;
  }

  .md-table-row {
    cursor: pointer;
  }

  .md-table-cell {
    height: 2.4rem;
  }

  .o-container-gray {
    margin-top: 0;
    padding-top: 3.2rem;
  }

  .btn-change-nameservers {
    display: block;
    margin: 1.6rem 0 .8rem 0;
    width: 100%
  }

  .container-item {
    margin: 5rem 0;
  }

  .container-nameservers {
    margin-left: 0;
    max-width: 50%;
    min-width: 44rem;
    text-align: right;
  }

  .container-divider {
    margin: 0 3.2rem;
  }

  .highlighted {
    color: $palette-primary-color-two;
    font-weight: bold;
  }

  .nameserver-error-all {
    text-align: left;
  }

  .md-error {
    color: $palette-error;
  }

  .add-to-cart-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    justify-content: center;
  }

  .add-to-cart-header {
    padding-bottom: 0;
  }

  .add-to-cart-item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .btn-add-to-cart {
    margin-left: 0;
    width: 20rem;
  }

  .reservation-expire-date {
    color: $palette-secondary-color-five;
  }
}
</style>